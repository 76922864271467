// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "trix"
import "@rails/actiontext"



import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';

document.addEventListener('DOMContentLoaded', () => {
  const editor = grapesjs.init({
    container: '#gjs',
    fromElement: true,
    // Adaugă aici configurația și plugin-urile dorite
    storageManager: {
      id: 'gjs-',             // Prefix pentru cheile de stocare locală
      type: 'local',          // Tipul de stocare (local, remote, ...)
      autosave: true,         // Salvează automat
      autoload: true,         // Încarcă automat
      stepsBeforeSave: 1,     // Salvează la fiecare modificare
      // Adaugă URL pentru salvare și încărcare de conținut, dacă e necesar
    },
    plugins: [], // Listă plugin-uri
    // Aici poți specifica și alte opțiuni, cum ar fi blocurile personalizate, stilurile etc.
  });

  // Logica pentru salvarea conținutului
  // De exemplu, ascultător pentru un buton de salvare
  const btnSave = document.getElementById('save-button');
  btnSave.addEventListener('click', () => {
    const htmlContent = editor.getHtml();
    const cssContent = editor.getCss();
    // Aici poți folosi `htmlContent` și `cssContent` pentru a salva conținutul
    // Spre exemplu, trimite-le printr-un request AJAX la server
  });
});







Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "controllers"

window.initMap = function(...args) {
    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    event.args = args
    window.dispatchEvent(event)
  }

  import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))



